import CheckoutPage from '../../components/CheckoutPage';
import { getUserInfoSSR } from '../../utils/cf';

const Checkout: React.FC = () => {
  return <CheckoutPage pageType="default" />;
};

export default Checkout;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
